/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700); */
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  font-size: 38px;
  color: var(--main-color);
}


:root {
  --main-color: #00B960;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

* {
  box-sizing: border-box;
}

.calendar header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

.calendar header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

.calendar header #logo .icon {
  padding-right: .25em;
}


/* GRID */

.calendar .row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.calendar .row-middle {
  align-items: center;
}

.calendar .col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.calendar .col-start {
  justify-content: flex-start;
  text-align: left;
}

.calendar .col-center {
  justify-content: center;
  text-align: center;
}

.calendar .col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  /* border: 1px solid var(--border-color); */
}

.calendar .header {
  text-transform: uppercase;
  padding: 12px 0;
  /* border-bottom: 1px solid var(--border-color); */
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  /* margin-left: 1em; */
  margin-left: 0em;
}

.calendar .header .icon:last-of-type {
  /* margin-right: 1em; */
  margin-right: 0em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

.calendar .itemDay {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(225 232 242 / var(--tw-bg-opacity));

  border: 1px solid transparent;
  border-radius: 10px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
  transition: .3s;
}
.calendar .itemDay > button {
  background: var(--main-color);
}

.calendar .itemDay.active {
  background: #FFFFFF;
  border: 1px solid #00693E;
}
.calendar .itemDay.active > button {
  background: linear-gradient(152.04deg, #00693E -0.97%, var(--main-color) 81.57%), #F9F9F9;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 15px !important;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 15px !important;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  font-size: 18px !important;
  font-weight: 700;
  /* text-shadow: 0px 0px 0px #00693E; */
  -webkit-text-stroke: 1.5px #00693E; /* width and color */
  color: #00693E;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  font-size: 18px !important;
  font-weight: 700;
  /* text-shadow: 0px 0px 0px #00693E; */
  -webkit-text-stroke: 1.5px #00693E; /* width and color */
  color: #00693E;
}